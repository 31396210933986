<template>

  <div>
    <Navbar :nav-light="true" />



    <section class="section">
      <div class="container">
        <div class="row">
          <ul class="col container-filter list-unstyled categories-filter text-center mt-5 mb-0" id="filter">
            <li class="list-inline-item mt-4">
              <a class="categories border d-block text-dark rounded" @click="selectStep(1)" :class="{'active':stepSelected === 1}">1. Выбор воронки
                <i class="uil uil-check" v-if="stepOneCheck"></i>
                <i class="uil uil-redo" v-else></i>
              </a></li>
            <li class="list-inline-item">
              <a class="categories border d-block text-dark rounded" @click="selectStep(2)" :class="{'active':stepSelected === 2}">2. Выбор шаблона
                <i class="uil uil-check" v-if="stepTwoCheck"></i>
                <i class="uil uil-redo" v-else></i>
              </a></li>
            <li class="list-inline-item">
              <a class="categories border d-block text-dark rounded" @click="selectStep(3)" :class="{'active':stepSelected === 3}">3. Проверка персонализации
                <i class="uil uil-check" v-if="stepThreeCheck"></i>
                <i class="uil uil-redo" v-else></i>
              </a></li>
            <li class="list-inline-item">
              <a class="categories border d-block text-dark rounded" @click="selectStep(4)" :class="{'active':stepSelected === 4}">4. Отправка писем
                <i class="uil uil-check" v-if="stepFourCheck"></i>
                <i class="uil uil-redo" v-else></i>
              </a></li>
            <li class="list-inline-item">
              <a class="categories border d-block text-dark rounded" @click="startOverButton" ><i class="uil uil-sync mr-2"></i>Начать заново</a></li>
          </ul>
        </div>
        <!--end row-->
        <div class="container">



          <div v-if="stepSelected===1" class="p-4">
            <div class="table-responsive bg-white shadow rounded">
              <table class="table mb-0 table-center">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Воронка</th>
                  <th scope="col">Этап</th>
                  <th scope="col">Резюме</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="job in jobFunnelsClone">
                  <th scope="row">{{job.vacancy_id}}</th>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  @click="selectVacancy(job)">{{job.vacancy_name}}</td>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                      @click="selectVacancy(job)">{{job.funnel_stage_name}}</td>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                      @click="selectVacancy(job)">{{job.number_of_resumes}}</td>
                  <td><a class="text-success rounded" v-if="stepOneCheck" :class="{'active': true}">Выбран
                    <i class="uil uil-check"></i>
                  </a></td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>


          <div v-if="stepSelected===2" class="p-4">
            <div class="table-responsive bg-white shadow rounded">
              <table class="table mb-0 table-center">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Шаблон</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(emailTemplate,ix) in emailTemplates_Options">
                  <td scope="row">{{ix+1}}.</td>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  @click="selectTemplate(emailTemplate)">{{emailTemplate.value}}</td>
                  <td><a class="text-success rounded" v-if="stepTwoCheck" :class="{'active': true}">Выбран
                    <i class="uil uil-check"></i>
                  </a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>


          <div v-if="stepSelected===3" class="p-4">


            <input
                class="searchbtn btn btn-primary btn-block mb-3"
                value="Подтвердить"
                @click="applyEmailTemplateAll(emailTemplates_Options[0].id)"
            />

            <div class="table-responsive bg-white shadow rounded">

              <table class="table mb-0 table-center">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Резюме</th>
                  <th scope="col">Имя, используемое в письме</th>
                  <th scope="col">Полное имя</th>
                  <th scope="col">Email</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(resume,ix) in resumes">
                  <td>{{ix}}.</td>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    <a  @click="goToResume(resume.resume_id)" class="text-primary ml-3"
                  >{{resume.resume_id}}<i class="uil uil-arrow-right"></i></a>
                  </td>
                  <td>
                    <div class="form-group">
                    <div class="position-relative">
                      <user-icon class="fea icon-sm icons"></user-icon>
                      <input
                          name="name"
                          type="text"
                          class="form-control pl-5"
                          placeholder="Имя :"
                          v-model="resumes[ix].first_name"
                      />
                    </div>
                  </div></td>
                  <td>  {{resume.full_name}}</td>
                  <td>  {{resume.email}}</td>
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                      @click="removeResume(ix)">
                    <i class="uil uil-times text-danger"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>



          <div v-if="stepSelected===4" class="p-4">


            <div class="form-group">
              <label>Куда отправить тестовый email:</label>
              <div class="position-relative">
                <layers-icon
                    class="fea icon-sm icons"
                ></layers-icon>
                <input
                    name="name"
                    type="text"
                    class="form-control pl-5"
                    v-model="input_sql_apply.email_to"
                />
              </div>
            </div>

            <a
                title="подсказка"
                @click="sendTest"
                class="btn btn-outline-info rounded text-center"
            ><i class="uil uil-plus"></i> Послать тестовое письмо</a
            >



            <a
                id="div-with-loading"
                @click="sendToAll"
                class="btn btn-primary rounded text-center"
            ><i class="uil uil-plus"></i> Сделать рассылку
              <b-spinner v-if="loadingStatus"></b-spinner>
            </a>



          </div>




        </div>
        </div>
    </section>



    <Footer />

  </div>

</template>

<script>
import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";
import EmailLine from "./EmailLine";

import { MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,
GitBranchIcon, SkipForwardIcon } from "vue-feather-icons";

import {BSpinner, BToast,BToaster} from 'bootstrap-vue'

import store from "@/store/store";

export default {
  name: "EmailToCandidates",
  components: {
    Navbar,
    Footer,
    EmailLine,
    MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon, GitBranchIcon, SkipForwardIcon,
    BSpinner, BToast,BToaster,
  },
  mounted() {
    this.getAllVacancies();
    this.loadTemplateList();
  },

  watch: {

    stepOneCheck(val) {
      if (val && this.stepTwoCheck) {
        console.log('stepTwoCheck')
        console.log(val)
        console.log(this.jobFunnelsClone)
        let vacancy_id = this.jobFunnelsClone[0].vacancy_id
        let funnel_stage_order_id = this.jobFunnelsClone[0].funnel_stage_order_id
        this.getResumes(vacancy_id,funnel_stage_order_id)
      }
    },
    stepTwoCheck(val) {
      if (val && this.stepOneCheck) {
        console.log('stepOneCheck')
        console.log(val)
        console.log(this.jobFunnelsClone)
        let vacancy_id = this.jobFunnelsClone[0].vacancy_id
        let funnel_stage_order_id = this.jobFunnelsClone[0].funnel_stage_order_id
        this.getResumes(vacancy_id,funnel_stage_order_id)
      }
    },

    selectAllCheckbox: function (val) {
      if (this.resumes.length > 0) {

        for (let i = 0; i < this.resumes.length; i++) {
          if (val) {
            this.resumes[i].isSelected = true;
          } else {
            this.resumes[i].isSelected = false;
          }
        }
      }
    },
  },

  methods: {

    updateFilter(filterName) {
      this.filterCategory = filterName;
    },





    removeResume(ix) {
        console.log('inside removeResume ix = ' + ix);
        this.resumes.splice(ix,1);
    },

    goToResume(x) {
      let route = this.$router.resolve({path: '/resume/'+x});
      window.open(route.href, '_blank');
    },


    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/job-description/list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {

        this.jobFunnelsClone = res.data.funnel_arr;
        this.jobFunnels = res.data.funnel_arr;
        console.log('inside get all vacancies in /email');
        console.log(this.jobFunnels);

        this.allVacancies = res.data.list;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },


    getAllVacanciesold() {


      this.input_save_vacancy.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.jobFunnelsClone = res.data.funnel_arr;
        this.jobFunnels = res.data.funnel_arr;
        console.log('inside get all vacancies in /email');
        console.log(this.jobFunnels);
      });

    },

    getResumes(vacancy_id,funnel_stage_order_id) {
      this.resumes=[];
      console.log('inside get getResumes')
      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.vacancy_id = vacancy_id;
      this.input_save_vacancy.funnel_stage_order_id = funnel_stage_order_id;
      console.log(this.input_save_vacancy);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-weaviate/get-resumes-minimal?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.resumes = res.data.resumes;
        console.log(this.resumes)

        /*
        var ev = document.createEvent('KeyboardEvent');
        ev.initKeyboardEvent(
            'keydown', true, true, window, false, false, false, false, 27, 0);
        //ev.initKeyboardEvent("keypress",27);

        ev.initKeyboardEvent("keypress",       // typeArg,
            true,             // canBubbleArg,
            true,             // cancelableArg,
            null,             // viewArg,  Specifies UIEvent.view. This value may be null.
            false,            // ctrlKeyArg,
            false,            // altKeyArg,
            false,            // shiftKeyArg,
            false,            // metaKeyArg,
            27,               // keyCodeArg,
            0);              // charCodeArg);
            document.body.dispatchEvent(ev);
         */

      });
      this.input_sql_apply.funnel_stage_order_id = funnel_stage_order_id;
      this.input_sql_apply.vacancy_id = vacancy_id;
    },

    loadTemplateList(){

      this.input_save_template.user_id = store.state.user_id;
      this.input_save_template.selectForm = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-template-str?access_token=' + store.state.token,
        data: this.input_save_template,
      }).then((res) => {
        this.emailTemplates = res.data.arr;

        if (this.emailTemplates.length>0) {
          this.emailTemplates_Options = [];

          for (let i = 0; i < this.emailTemplates.length; i++) {
            this.emailTemplates_Options.push({
              'id': this.emailTemplates[i].template_id,
              'value': this.emailTemplates[i].template_name
            })
          }
          this.emailTemplates_Selected = {
            'id': this.emailTemplates[0].template_id,
            'value': this.emailTemplates[0].template_name
          };
          console.log('inside loadTemplateList')
          console.log(this.emailTemplates);

          console.log(this.emailTemplates_Options)
        }

      });

    },


    updateResume(e) {
      console.log(e);

      this.resumes[e.index].isSelected = e.isSelected

    },

    indexListen(e){
      this.input_sql_apply.resume_id = this.resumes[e].resume_id;

      this.input_sql_apply.user_id = store.state.user_id;


      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-email-to-send?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {
        console.log(res.data);
        this.showEmail = res.data;
      }).catch((err) => {
        console.error(err);
      });

    },

    applyEmailTemplateSelected(template_id) {
      console.log('inside apply email template')

      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.template_id = template_id;
      this.input_sql_apply.updateFlag = 0;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
        if (this.resumes[i].isSelected) {
          this.input_sql_apply.resumes.push(this.resumes[i].resume_id);
        }
      }
      //this.input_sql_apply.updateFlag = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/apply-email-template?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);
        this.getResumes(this.input_sql_apply.vacancy_id, this.input_sql_apply.funnel_stage_order_id);
        /*this.validateStatus = res.data.validateStatus;
        for (let i=0; i<this.resumes.length; i++){
          let id_run = this.resumes[i].resume_id;
          this.resumes[i].isValidated = this.validateStatus.filter(x => x.resume_id ===id_run).map(x => x.isValidated);
          console.log( 'i = ' +id_run+' ' + this.resumes[i].isValidated)
        }*/
      }).catch((err) => {
        console.error(err);
      });
    },

    applyEmailTemplateAll(template_id) {
      console.log('inside apply email template')

      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.template_id = template_id;
      this.input_sql_apply.updateFlag = 0;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
          this.input_sql_apply.resumes.push(this.resumes[i]);
      }
        console.log(this.input_sql_apply)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email-weaviate/apply-email-template?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);

      //  this.$vs.notify({
      //    time: 8000,
      //    title: 'Готово',
      //    text: 'Шаблон применен, осталось сделать рассылку',
      //    color: 'success'
      //  });
        this.$bvToast.toast('Шаблон применен, осталось сделать рассылку', {
          title: `Готово`,
          variant: 'success',
          solid: true,
          autoHideDelay: 8000,
        });
        this.stepThreeCheck = true;

      }).catch((err) => {
        console.error(err);
      });


    },


    sendToSelected() {


      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
        if (this.resumes[i].isSelected) {
          this.input_sql_apply.resumes.push(this.resumes[i].resume_id);
        }
      }

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/send-to-selected?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);
        if (res.data.status==='ok'){
          this.getResumes(this.input_sql_apply.vacancy_id, this.input_sql_apply.funnel_stage_order_id);
        }
        /*
        this.validateStatus = res.data.validateStatus;
        for (let i=0; i<this.resumes.length; i++) {
          let id_run = this.resumes[i].resume_id;
          this.resumes[i].isSent = this.validateStatus.filter(x => x.resume_id ===id_run).map(x => x.isSent);
          //console.log( 'i = ' +id_run+' ' + this.resumes[i].isValidated)
        }
        */
      }).catch((err) => {
        console.error(err);
      });


    },


    sendTest() {


      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < 1; i++) {

          this.input_sql_apply.resumes.push(this.resumes[i].resume_id);

      }

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/send-one-test?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);


        //this.$vs.notify({
        //  time: 8000,
        //  title: 'Готово',
        //  text: 'Рассылка была разослана кандидатам',
        //  color: 'success'
       // });
        this.$bvToast.toast('Тестовое письмо было отправлено', {
          title: `Готово`,
          variant: 'success',
          solid: true,
          autoHideDelay: 8000,
        });


      }).catch((err) => {
        console.error(err);
      });


    },

    sendToAll() {


      this.input_sql_apply.user_id = store.state.user_id;
      this.input_sql_apply.resumes = [];
      for (let i = 0; i < this.resumes.length; i++) {
        this.input_sql_apply.resumes.push(this.resumes[i].resume_id);
      }

     // this.$vs.loading({
     //   container: '#div-with-loading',
        //container: this.$refs.loginButton.$el,
     //   scale: '0.45',
     // });

      this.loadingStatus = true;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/send-to-selected?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {

        console.log(res.data);


      //  this.$vs.notify({
      //    time: 8000,
      //    title: 'Готово',
      //    text: 'Рассылка была разослана кандидатам',
      //    color: 'success'
      //  });
        this.$bvToast.toast('Рассылка была разослана кандидатам', {
          title: `Готово`,
          variant: 'success',
          solid: true,
          autoHideDelay: 8000,
        });

       // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.loadingStatus = false;
      }).catch((err) => {
        console.error(err);
        this.loadingStatus = false;
       // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      });


    },





    selectVacancy(vacancyObj) {
      console.log('selectVacancy')
      console.log(vacancyObj)
      this.jobFunnelsClone = [];
      this.jobFunnelsClone.push(vacancyObj);
      console.log(this.jobFunnelsClone)
      this.stepOneCheck = true;
    },

    selectTemplate(templateObj) {
      this.emailTemplates_Options = [];
      this.emailTemplates_Options.push(templateObj);
      this.stepTwoCheck = true;
    },

    startOverButton() {
          this.stepOneCheck=false;
          this.stepTwoCheck=false;
          this.stepThreeCheck=false;
          this.stepFourCheck=false;

          this.jobFunnelsClone = []
          for (let i = 0; i < this.jobFunnels.length; i++) {
            this.jobFunnelsClone.push(this.jobFunnels[i]);
          }

          this.emailTemplates_Options = [];

          for (let i = 0; i < this.emailTemplates.length; i++) {
            this.emailTemplates_Options.push({
              'id': this.emailTemplates[i].id,
              'value': this.emailTemplates[i].name
            })
          }
    },

    selectStep(x) {
      this.stepSelected = x;
    },



  },
  data(){
    return{
      loadingStatus: false,

      stepOneCheck:false,
      stepTwoCheck:false,
      stepThreeCheck:false,
      stepFourCheck:false,

      filterCategory: "all",

      stepSelected: 1,

      hover:false,
      activeVacancies:[],
      jobFunnels: [],
      jobFunnelsClone: [],
      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
        funnel_stage_order_id:1,
      },
      resumes:[],
      selectAllCheckbox: false,
      emailTemplates_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      emailTemplates_Selected : {id:1,value:'По дате добавления резюме'},

      emailTemplates: [],


      content: '<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul><p><br></p>',
      editorOption: {

        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },


      selected_vacancy_id:0,
      selected_funnel_stage_order_id:0,

      input_save_template: {
        user_id: 1,
        selectForm: 0,

        template_id:1,
        template_name: '',

        from_name: '',
        from_email: '',
        subject: 'sa',
        emailBody: '',
        bcc_email: '',

        name_new: '',

        toDelete: 0,
      },

      input_sql_apply: {
        funnel_stage_order_id: 1,
        funnel_stage_name: '',

        vacancy_id: 1,
        vacancy_name: '',

        user_id: 1,
        resumes: [],
        emailTemplate: {},
        updateFlag:0,

        to_email: '',
        email_to: '',
        resume_id:1,
      },

      showEmail: {
        to_name: '',
        to_email: '',
        from_name: '',
        from_email: '',
        subject: '',
        emailBody_str: '',
      },

    }
  },


}
</script>

<style scoped>

</style>